.popup-card img {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.ls_content-resp {
    font-family: var(--title-font);
    padding: 16px;
    font-weight: 400;
    color: var(--light-color);
    font-size: 14px;
}

.popup-card {
    z-index: 9999;
    background-color: white;
    position: fixed;
    bottom: 10px;
    left: 20px;
    right: 20px;
    padding: 3px;
    transition: 1s ease;
    border-radius: 8px;
    transform: translateY(110%);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.popup-card.active {
    transform: translateY(0);
}

.gen-icon {
    font-size: 14px;
    margin: 6px 0 6px 6px;
}

.popup-card button {
    background-color: white;
    padding: 0;
    height: fit-content;
    border: none;
    outline: none;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 600px) {
    .popup-card {
        width: 330px;
        right: auto;
    }
}