.carousel-size {
    width: 100%;
    height: 280px;
    object-fit: cover;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 320px) {
    .feature-title {
        font-size: 20px;
    }

    .navbar_header {
        display: none;
    }

    .carousel-size-1 {
        width: 100%;
        height: 100px;
        object-fit: cover;
    }

    .small-header {
        display: flex;
    }

    .product-detail {
        margin-top: 30px;
        /* max-width: 70%; */
    }

    nav.navbar {
        display: none;
    }

    .overlay > div.main-modal {
        width: 80%;
    }

    .caption-content > h1 {
        font-size: 20px;
    }

    .caption-content > h1 + p {
        font-size: 13px;
    }

    /* .swiper-caption {
      left: 40px;
    } */
    .item {
        max-width: 100%;
    }

    .product-row {
        /* padding: 15px; */
    }

    .view-more,
    .join-now {
        padding: 10px 30px;
        width: fit-content;
    }

    .shop-now {
        padding: 10px 30px;
    }

    .marketplace-wrap {
        width: 100%;
        background-color: white;
    }

    .marketplace-wrap h1 {
        font-size: 20px;
    }

    .marketplace-wrap .content {
        text-align: justify;
        font-size: 14px;
        line-height: 24px;
    }

    .flexrow {
        height: auto;
        margin-bottom: 20px;
    }

    .white-lay {
        background-color: rgba(255, 255, 255, 0.947);
    }

    .companies {
        padding: 2rem 0.5rem;
    }

    .footer-wrap {
        display: none;
    }

    .product-filter {
        justify-content: center;
        flex-wrap: wrap;
    }

    .resp-hide {
        display: none;
    }

    .filter-btn {
        display: block;
    }

    .top-header {
        display: none;
    }

    .flex-colmn {
        margin-bottom: 20px;
    }

    .morefromvendor {
        font-size: 12px;
        font-weight: 500;
        padding: 6px 12px;
    }

    .list-item .product-row h4 {
        font-size: 14px;
        margin-bottom: 0.2rem;
    }

    .list-item .price {
        font-size: 14px;
        margin-bottom: 0.2rem;
    }

    .list-item .description {
        color: #737373;
        margin-bottom: 0.2rem;
        text-align: start;
        font-size: 12px;
    }

    .list-item .add-cart span {
        display: none;
    }

    .list-item .product-cover {
        height: auto;
    }

    .list-item .add-cart {
        padding: 5px 20px;
    }

    .wishlist-icon i {
        font-size: 18px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .sortProductContainer {
        flex-wrap: nowrap;
        justify-content: space-between;
    }

    .feature-title {
        font-size: 24px;
    }

    .carousel-size-1 {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }

    .default-width {
        width: 460px;
        margin: 0 auto;
    }

    .morefromvendor {
        font-size: 14px;
        font-weight: 700;
        padding: 10px 20px;
    }

    .caption-content > h1 + p {
        font-size: 14px;
    }

    .flexrow {
        margin-top: 23px;
    }

    .flex-colmn {
        height: 200px;
        margin-bottom: 0;
    }

    .item {
        /* max-width: 33%; */
    }

    .product-row {
        /* padding: 8px; */
    }

    .overlay > div.main-modal {
        width: 70%;
    }

    .flexrow {
        height: 200px;
        margin-bottom: 0;
    }

    .footer-wrap {
        display: block;
    }

    .marketplace-wrap {
        width: 413px;
        margin: 60px auto 0px auto;
        background-color: transparent;
    }

    .marketplace-wrap .content {
        text-align: center;
    }

    .list-item .product-row h4 {
        font-size: 16px;
        margin-bottom: 1rem;
    }

    .list-item .price {
        font-size: 16px;
        margin-bottom: 1rem;
    }

    .list-item .description {
        text-align: start;
        margin-bottom: 1rem;
        font-size: 14px;
    }

    .list-item .add-cart span {
        display: block;
    }

    .list-item .product-cover {
        position: static;
        height: 200px;
    }

    .list-item .add-cart {
        padding: 10px 20px;
    }

    .wishlist-icon i {
        font-size: 24px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .login-width > div:first-child {
        width: fit-content;
        padding-right: 40px;
    }

    .carousel-size-1 {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }

    .login-width > div:last-child {
        border-left: 1px solid #eeeeee;
        padding-left: 40px;
    }

    .login-width {
        gap: 0;
    }

    .product-detail {
        margin-top: 0;
    }

    .login-width {
        flex-wrap: nowrap;
    }

    .newsflash > p,
    .clgrid {
        font-size: 12px;
    }

    .div#magNav .navbar-nav {
        justify-content: flex-start;
    }

    .caption-content > h1 {
        font-size: 24px;
    }

    .caption-content > h1 + p {
        font-size: 16px;
    }

    .overlay-heading {
        font-size: 13px;
    }

    .item {
        /* max-width: 33%; */
    }

    .marketplace-wrap h1 {
        font-size: 28px;
    }

    .marketplace-wrap {
        width: 524px;
    }

    .flexrow {
        margin-top: 0px;
    }

    .rm-2 {
        margin-bottom: 23px;
    }

    .flex-colmn {
        height: 100%;
    }

    .overlay > div.main-modal {
        width: 50%;
    }

    .white-lay {
        background-color: transparent;
    }

    .companies {
        padding: 2rem 3rem;
    }

    .resp-hide {
        display: flex;
    }

    .filter-btn {
        display: none;
    }

    .top-header {
        display: block;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .sale-child{
        width: 50%;
    }
    .login-width {
        max-width: 800px;
    }

    .carousel-size-1 {
        width: 100%;
        height: 280px;
        object-fit: cover;
    }

    .product-filter {
        justify-content: flex-end;
    }

    .searchtxt {
        max-width: 374px;
    }

    .signbtn {
        white-space: nowrap;
    }

    .respon {
        height: 50vh;
        background-size: 35% 100%, 21% 80%;
    }

    .navbar_header {
        display: block;
    }

    .small-header {
        display: none;
    }

    .div#magNav .navbar-nav {
        justify-content: space-around;
    }

    nav.navbar {
        display: block;
    }

    .overlay {
        display: none;
    }

    .caption-content > h1 {
        font-size: 40px;
    }

    .overlay-heading {
        font-size: 16px;
    }

    .product-row {
        /* padding: 15px; */
    }

    /* .item {
      max-width: 25%;
    } */
    .marketplace-wrap h1 {
        font-size: 40px;
    }

    .marketplace-wrap {
        width: 686px;
    }

    .marketplace-wrap .content {
        font-size: 20px;
        line-height: 30px;
    }

    .view-more,
    .join-now {
        padding: 15px 55px;
        /* width: 260px; */
    }

    .shop-now {
        padding: 15px 46px;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

/* Patched */

@media screen and (min-width: 575px) and (max-width: 600px) {
    .flex-colmn {
        height: 200px;
        margin-bottom: 0;
    }

    .flexrow {
        height: 200px;
        margin-bottom: 0px;
    }
}

/* modal popup */
.lifTimePopup-container {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    z-index: 1000;
    background-color: #1d1b1b6c;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 8px; */
}

.hiddenLiftTimePopup {
    opacity: 0;
    transform: translateY(0);
    animation: fadeInDown 0.3s ease;
}

.visibleLiftTimePopup {
    opacity: 1;
    transform: translateY(0);
    animation: fadeOutUp 0.3s ease;
}

.popup_main_bg {
    margin: 0;
    padding: 0;
    max-width: 720px;
    width: 100%;
    height: 100%;
    max-height: 405px;
    background: linear-gradient(
            108deg,
            #4c4c4c 0%,
            #282828 85.24%,
            #060606 91.8%
    );
}

.popup_bg {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background-image: url("../../../assets/images/home/pimg1.png"),
    url("../../../assets/images/home/pimg2.png"),
    url("../../../assets/images/home/pimg3.png"),
    url("../../../assets/images/home/pimg4.png"),
    url("../../../assets/images/home/pimg5.png"),
    url("../../../assets/images/home/pimg6.png");
    background-position: top left, left center, bottom left, top right,
    right center, bottom right;
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat,
    no-repeat;
    background-size: auto, auto, auto, auto, auto, auto;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* .popup_content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

.popup_title {
    color: #feab0e;
    font-family: Montserrat;
    font-size: 20px;
    font-style: italic;
    font-weight: 500;
    line-height: 150%; /* 30px */
    letter-spacing: -0.8px;
    text-align: center;
}

.popup_sub_title {
    color: #f3f3f3;
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: italic;
    font-weight: 500;
    line-height: 150%; /* 21px */
    letter-spacing: -0.56px;
}

.popup_form {
    max-width: 330px !important;
    width: 100%;
    margin: 26px auto 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.popup_form .popup_input {
    width: 100%;
    min-height: 30px;
    border: 1px solid #d7d7d7;
    background: #fff;
    color: #252b42;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 0%; /* 0px */
    letter-spacing: -0.56px;
}

.popup_form .popup_input_error {
    width: 100%;
    min-height: 30px;
    border: 1px solid #fc0000 !important;
    background: #fff;
    color: #252b42;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 0%; /* 0px */
    letter-spacing: -0.56px;
}

.popup_submit_btn {
    width: 100%;
    display: flex;
    height: 30px !important;
    justify-content: center;
    align-items: center;
    background: #feab0e;
    color: #fff;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 0%; /* 0px */
    letter-spacing: -0.48px;
    outline: none;
    border: none;
}

.popup_close_btn {
    border: none;
    outline: none;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -12px;
    right: -12px;
    background-color: #feab0e;
    color: white;
}

/* .popup_close_btn:hover {
  background-color: #c88508;
  animation-duration: 1s;
} */

.popup_close_btn img {
    width: 30px;
    height: 30px;
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeOutUp {
    from {
        opacity: 1;
        transform: translateY(0);
    }
    to {
        opacity: 0;
        transform: translateY(-20px);
    }
}

@media screen and (max-width: 575px) {
    .lifTimePopup-container {
        padding: 10px;
    }

    .popup_bg {
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        background-image: url("../../../assets/images/home/pimg1.png"),
        url("../../../assets/images/home/pimg2.png"),
        url("../../../assets/images/home/pimg3.png"),
        url("../../../assets/images/home/pimg4.png"),
        url("../../../assets/images/home/pimg5.png"),
        url("../../../assets/images/home/pimg6.png");
        background-position: top left, left center, bottom left, top right,
        right center, bottom right;
        background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat,
        no-repeat;
        background-size: 50px, 50px, 50px, 50px, 50px, 50px;
    }

    .popup_content {
        padding: 10px;
    }
}