.add-review-container {
  max-width: 598px;
  min-height: 987px;
  background-color: white;
}

.add-review-container .review-label {
  color: #000;
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.add-review-container .review-product-label {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.add-review-container .review-product-p {
  color: rgba(0, 0, 0, 0.57);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.add-review-container .comment-input-field {
  max-width: 535px;
  min-height: 124px;
  max-height: 350px;
  flex-shrink: 0;
  border: 1px solid #dedede;
  resize: none;
}

.add-review-container .review-images-container .browse-text {
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.add-review-container .review-images-container .image-input {
  width: 100%;
  max-height: 50px;
}
.add-review-container .review-images-container .image-input .browse-btn {
  width: 96px;
  height: 50px;
}

.add-review-container .review-img-container {
  max-width: 117px;
  border: 1px solid #dedede;
}
.add-review-container .review-img-container img {
  width: 117px !important;
  height: 65px;
  max-width: 117px;
  flex-shrink: 0;
}
.add-review-container .review-img-container h1 {
  color: var(--text-color, #252b42);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.1px;
}
.add-review-container .review-img-container p {
  color: var(--second-text-color, #737373);
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
}

.review-img-input-instructions {
  color: rgba(0, 0, 0, 0.57);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 30px;
}

.review-actions-btn .review-cancel-btn {
  color: #737373;
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
  letter-spacing: 0.2px;
  border: 1px solid #d7d6d6;
}

.review-actions-btn .review-submit-btn {
  color: var(--light-text-color, #fff);
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
  letter-spacing: 0.2px;
  text-transform: uppercase;
}

.review_user {
  display: flex;
  align-items: center;
  gap: 5px;
}
.review_user img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}
