.testimonials {
    padding: 40px 0;
}

.testimonials .home-about-heading {
    margin-bottom: 40px;
}

.testimonial-card {
    display: flex;
    flex-direction: column;
    gap: 20px
}

.testimonial-card .flx {
    display: flex;
    justify-content: center;
    gap: 20px;
    align-items: center;
}

.testimonial-card .ls_content {
    line-height: 22px;
    font-size: 14px;
    width: 80%;
    margin: auto;
}

.svg-width > svg {
    width: 30px
}

.testimonial-card .flx figure {
    width: 100px;
    margin-bottom: 0;
    height: 100px;
}

.testimonial-card .flx figure img {
    width: 100%;
    border-radius: 50%;
    height: 100%;
    object-fit: cover;
}

.testimonial-card strong {
    font-size: 20px;
}

.seller-rev {
    display: flex;
    justify-content: center;
    gap: 10px
}

.seller-rev figure {
    margin-bottom: 0;
    width: 100px;
    height: 100px;
}

.seller-rev figure img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.testimonial-card .flx {
    color: var(--theme-color)
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 600px) {
    .svg-width > svg {
        width: 40px !important;
    }

    .testimonial-card strong {
        font-size: 24px;
    }

    .testimonial-card .ls_content {
        line-height: 30px;
        font-size: 18px;
        width: 70%;
    }

    .seller-rev figure {
        width: 200px;
        height: 200px;
    }
}