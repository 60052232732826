.seller_banner_container {
  /* background-image: url("../../assets//images//seller-page//banner.png"); */
  background-repeat: no-repeat;
  background-size: 100% 597px;
  height: 597px;
}

.seller_banner_overlay {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to top, #1b1b1bec, rgba(51, 50, 50, 0.425));
}

.seller_banner_sub_title {
  color: rgba(255, 255, 255, 0.87);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
  letter-spacing: 0.1px;
}

.seller_banner_overlay button {
  color: var(--light-text-color, #fff);
  text-align: center;

  /* btn-text */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 157.143% */
  letter-spacing: 0.2px;
  margin-top: 10px;
  padding: 10px 24px;
}

.seller_banner_title {
  color: var(--light-text-color, #fff);
  text-align: center;
  font-family: Montserrat;
  font-size: 58px;
  font-style: normal;
  font-weight: 700;
  line-height: 67px; /* 115.517% */
  letter-spacing: 0.2px;
}
.seller_banner_content {
  color: var(--light-text-color, #fff);
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
}

/* section 2 */
.why_sell_on_mangtum {
  text-align: center;
  color: var(--text-color, #252b42);

  /* h2 */
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px; /* 125% */
  letter-spacing: 0.2px;
}
.why-choose-us-text {
  color: var(--second-text-color, #737373);
  text-align: center;

  /* paragraph */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  letter-spacing: 0.2px;
}

.seller_list_container {
  margin-top: 50px;
}

.seller_list_container h1 {
  color: var(--text-color, #252b42);
  font-family: Montserrat;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px; /* 145.455% */
  letter-spacing: 0.1px;
}

.seller_list_container span {
  color: var(--second-text-color, #737373);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 155.556% */
  letter-spacing: 0.2px;
  margin-left: 10px;
}

/* how to sell */

.how_to_sell_card h2 {
  color: #222;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
}
.how_to_sell_card p {
  color: #222;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.399px; /* 139.993% */
}

@media screen and (max-width: 768px) {
  .seller_banner_container {
    background-size: cover;
  }
  .seller_banner_title {
    color: var(--light-text-color, #fff);
    text-align: center;
    font-family: Montserrat;
    font-size: 37px;
    font-style: normal;
    font-weight: 700;
    line-height: 45px; /* 115.517% */
    letter-spacing: 0.2px;
  }
}
