.cart-drawer_container {
    width: 360px;
    display: flex;
    flex-direction: column;
    background-color: #F8F9FA;
    position: fixed;
    transition: 0.5s ease;
    transform: translateX(100%);
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1000
}

.active {
    transform: translateX(0%);
}

.cart-drawer_top {
    display: flex;
    padding: 10px 20px;
    justify-content: space-between;
    align-items: center;
    background-color: #F1F1F1;
    border-bottom: 1px solid #ccc;
}

.cart-drawer_top button {
    outline: none;
    border: none;
    background-color: transparent;
}

.cart-drawer_top button svg {
    font-size: 20px;
    color: #333;
}

.cart-drawer_body {
    padding: 10px 20px;
    display: flex;
    overflow-y: auto;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
}

.cart-drawer_body li {
    margin-bottom: 10px;
}

.cart-drawer_body figure {
    width: 80px;
    height: 80px;
    margin-bottom: 0;
}

.cart-drawer_body figure img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    object-fit: cover;
}

.cart-drawer_body ul {
    margin: 0;
    padding: 0;

}

.cart-card_body {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: 3px
}

.cart-card_body p {
    font-size: 13px;
    font-weight: 500;
    font-family: var(--title-font)
}

.cart-card_body label {
    margin-bottom: 0;
    margin-right: 10px;
}

.cart-drawer_body a {
    display: flex;
    gap: 12px;
    align-items: center;
}

.cart-card_body > * {
    display: block;
    color: #000000;
}

.cart-card_body > span:first-child {
    color: var(--theme-color);
}

.cart-card_body > span:nth-child(2) {
    font-weight: bold;
    font-size: 16px;
}

.cart-card_body > span:last-child {
    font-weight: 300;
}

.sub-total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    font-size: 17px;
}

.sub-total > strong:last-child {
    color: #e31313;
}

.action-buttons {
    display: flex;
    gap: 10px
}

.action-buttons > a {
    background-color: var(--theme-color);
    display: block;
    color: white;
    text-align: center;
    font-size: 15px;
    border: none;
    outline: none;
    border-radius: 6px;
    padding: 10px 20px;
    width: 50%;
}