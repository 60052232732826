html {
    overflow-x: hidden;
}

/* common styles */
.text-wrap {
    word-wrap: break-word;
}

.text-one-line {
    white-space: nowrap;
    text-overflow: clip;
    overflow: hidden;
}

.pointer {
    cursor: pointer;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

.cursor-none {
    cursor: none !important;
}

.text-theme {
    color: #f6a92c !important;
}

.border-theme {
    border-color: #f6a92c !important;
}

.text-theme-blue {
    color: #0059a8 !important;
}

.text-theme-gray {
    color: #f8f8f8 !important;
}

.bg-theme-gray {
    background-color: #f8f8f8 !important;
}

.bg-theme-blue {
    background-color: #0059a8 !important;
}

.bg-theme-blue:hover {
    background-color: #054d8b !important;
}

.bg-theme {
    background-color: #f6a92c !important;
}

.bg-theme:hover {
    background-color: #d48505 !important;
}

.font-theme {
    font-family: Montserrat;
}

.bg-green {
    background-color: #f8a01142;
}

.oneLine {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

body {
    -webkit-font-smoothing: antialiased !important;
    padding: 0;
    margin: 0;
    font-family: "Poppins", sans-serif;
    color: #444444;
    overflow-x: hidden !important;
    overflow-y: auto !important;
}

body p {
    margin-bottom: 0px;
}

/* common styles */

/* Default grid for small devices */

.single-product-section {
    margin-top: 20px;
}

/* ---------------home page start-------------------  */

.banner-align {
    padding-left: 0;
}

/* ----------single product start----------- */
.magnifier-container {
    max-height: 300px;
    position: relative;
}

.previewImg img {
    min-height: 200px;
    margin: 0 auto;
}

.img-size {
    width: 100%;
    height: 180px;
    object-fit: cover;
    margin: 0 auto;
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
    .img-size {
        width: 100%;
        height: 200px;
        object-fit: fill;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 767px) {
    .product-content {
        padding: 0 5px;
        min-height: 50px !important;
    }

    .banner-overly {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 50;
        background: linear-gradient(to top, #1b1b1be1, rgba(51, 50, 50, 0.171));
    }

    .banner-align {
        text-align: center;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: end;
    }

    .content-size {
        max-width: 350px;
        margin: 20 auto 0 auto;
        padding: 20px;
    }

    .previewImg img {
        min-height: 200px;
        max-width: 350px;
        margin: 0 auto;
        position: relative;
    }

    .magnifier-container {
        max-height: 300px;
        /* max-width: 350px; */
        margin: 0 auto;
        position: relative;
    }

    .image-gallery-slide {
        /* max-width: 400px !important; */
        margin: 0 auto;
    }

    .image-gallery-thumbnail-image {
        max-height: 60px;
    }
}

/* -----------------Tablet Devices----------------- */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .img-size {
        width: 100%;
        height: 180px;
        object-fit: cover;
        margin: 0 auto;
    }

    .banner-overly {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 50;
        background: linear-gradient(to top, #1b1b1be1, rgba(51, 50, 50, 0.171));
    }

    .banner-align {
        text-align: center;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: end;
    }

    .content-size {
        max-width: 350px;
        margin: 20 auto 0 auto;
        padding: 20px;
    }

    .magnifier-container {
        max-height: 300px;
        max-width: 350px;
        margin: 0 auto;
        position: relative;
    }

    .previewImg img {
        min-height: 300px;
        width: auto;
        object-fit: fill;
        position: relative;
        margin: 0 auto;
    }

    .image-gallery-thumbnail-image {
        max-height: 60px;
    }
}

/* Desktop Devices */
@media only screen and (min-width: 992px) {
    .img-size {
        width: 100%;
        height: 180px;
        object-fit: cover;
        margin: 0 auto;
    }

    .banner-overly {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 50;
        background: linear-gradient(
                to right,
                #131312a9,
                rgba(255, 255, 255, 0.034)
        );
    }

    .banner-align {
        text-align: left;
        padding-left: 120px;
    }

    .single-product-section {
        margin-top: 50px;
    }

    .magnifier-container {
        max-height: 450px;
        position: relative;
    }

    .previewImg img {
        min-height: 350px;
        position: relative;
        margin: 0 auto;
    }
}

/* --------------------home page end-------------------- */

.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin: 0 auto;
}

.image-container {
    position: relative;
    width: 100%;
    background-color: rgb(222, 227, 230);
}

.image-container:hover {
    overflow: hidden;
}

.image-container:hover img {
    position: relative;
    transform: scale(1.5);
    transition: transform 0.5s ease;
}

.des-container {
    min-height: 30px;
}

.product-image {
    height: 200px;
}

/* Media query for medium-sized devices */
@media screen and (min-width: 508px) {
    .grid-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .row-image-size {
        height: 140px !important;
    }
}

/* Media query for medium-sized devices */
@media screen and (min-width: 768px) {
    .grid-container {
        grid-template-columns: repeat(3, 1fr);
    }

    .previewImg img {
        min-height: 200px;
        width: auto;
        object-fit: fill;
        margin: 0 auto;
        position: relative;
    }
}

/* Media query for large-sized devices */
@media screen and (min-width: 992px) {
    .grid-container {
        grid-template-columns: repeat(4, 1fr);
    }
}

/* Media query for extra-large devices */
@media screen and (min-width: 1200px) {
    .grid-container {
        grid-template-columns: repeat(5, 1fr);
    }

    .img-size {
        width: 100%;
        height: 180px;
        object-fit: cover;
        margin: 0 auto;
        /* background-color: rgba(0, 0, 0, 0.384); */
    }
}

/* product image */
.arrow-r-btn,
.arrow-l-btn {
    opacity: 0;
    /* Start with opacity 0 */
    transition: opacity 0.5s ease;
    width: 40px;
    height: 80px;
    outline: none;
    border: none;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    position: absolute;
    z-index: 5000;
    display: flex;
    justify-content: center;
    align-items: center;
    /* Add a transition animation */
}

.arrow-l-btn {
    left: 0;
}

.arrow-r-btn {
    right: 0;
}

.arrow-l-btn:hover {
    background-color: #d48505;
}

.arrow-r-btn:hover {
    background-color: #d48505;
}

.arrow-parent:hover .arrow-r-btn,
.arrow-parent:hover .arrow-l-btn {
    display: block;
    opacity: 1;
    /* Change opacity to 1 on hover */
}

.arrow-parentInModal .arrow-r-btn,
.arrow-parentInModal .arrow-l-btn {
    display: block;
    opacity: 1;
}

.Toastify__toast-enter {
    animation: Toastify__slideInRight 0.1s;
}

.Toastify__toast-exit {
    animation: Toastify__slideOutRight 0.2s;
}

.text-wrap {
    text-wrap: wrap !important;
}

/* product image */

/* product info tabs design */
.review-title {
    color: var(--text-color, #252b42);
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 160% */
    letter-spacing: 0.1px;
}

.review-count {
    color: var(--text-color, #252b42);
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 133.333% */
    letter-spacing: 0.1px;
}

.total-reviews {
    color: #737373;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 228.571% */
    letter-spacing: 0.1px;
}

.review-count-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    text-align: center;
}

.review-range-label {
    color: #737373;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 266.667% */
    letter-spacing: 0.1px;
}

.star-svg {
    margin-top: 0px !important;
    padding-top: 0px !important;
}

.filter-review-title {
    color: #252b42;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 200% */
    letter-spacing: 0.1px;
}

.filter-review-btn-text-active {
    color: #fff;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.2px;
}

.filter-review-btn-text {
    color: var(--second-text-color, #737373);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: 0.2px;
}

.review-buyer {
    color: #737373;
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 32px; /* 320% */
    letter-spacing: 0.1px;
}

.user-review-paragraph {
    color: var(--second-text-color, #737373);

    /* paragraph */
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.2px;
}

/* product info tabs design */

.filter_reset_btn {
    width: 80px;
    height: 35px;
    border-radius: 28px;
    border: none;
    font-size: 14px;
    background-color: #f79c0938;
    font-family: Montserrat;
    font-weight: 600;
    cursor: pointer;
}

.filter_reset_btn:hover {
    background-color: #f6a92c;
    color: white;
    animation-duration: 0.5s;
    transition-duration: 0.2s;
}

.blurred-img {
    background-image: url('./assets/images/global/sellerstore.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.blurred-img img {
    opacity: 0;
}

.blurred-img {
    filter: blur(10px);
}

.blurred-img::before {
    content: "";
    position: absolute;
    inset: 0;
    opacity: 0;
    animation: pulse 2.5s infinite;
    background-color: white;
}

@keyframes pulse {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.1;
    }
    100% {
        opacity: 0;
    }
}

.blurred-img {
    background-repeat: no-repeat;
    background-size: cover;
}

.blurred-img::before {
    content: "";
    position: absolute;
    inset: 0;
    opacity: 0;
    animation: pulse 2.5s infinite;
    background-color: #F7C576;
}

@keyframes pulse {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.1;
    }
    100% {
        opacity: 0;
    }
}

.blurred-img.loaded {
    filter: none;
}

.blurred-img.loaded::before {
    animation: none;
    content: none;
}

.blurred-img img {
    opacity: 0;
    transition: opacity 250ms ease-in-out;
}

.blurred-img.loaded img {
    opacity: 1;
}