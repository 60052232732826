/* Mobile Devices */
.category-img {
    height: 300px;
}

@media only screen and (max-width: 767px) {
    .category-img {
        height: 300px;
    }
}

/* Tablet Devices */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    /* Your styles for tablet devices go here */
}

/* Desktop Devices */
@media only screen and (min-width: 992px) {
}