.video-container {
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: space-between;
    padding: 80px 0;
    font-family: 'Montserrat', sans-serif;
    flex-direction: column;
}

.video-middle {
    border-radius: 10px;
    overflow: hidden;
}

.video-right p {
    margin-bottom: 0.5rem;
}

.video-middle video {
    width: 100%;
    height: 100%;
}

.video-left img {
    width: 100%;
    height: 431px;
    object-fit: cover;
}

.video-right {
    width: 100%;
    text-align: center;
    display: inline-block;
}

.video-left {
    width: 100%;
    display: inline-block;
}

/* Small devices (sm) */
@media (min-width: 640px) {
    .video-container {
        flex-direction: row;
        flex-wrap: wrap;
        height: auto;
    }
}

/* Medium devices (md) */
@media (min-width: 768px) {

    .video-middle video {
        object-fit: contain;
    }

    .video-middle {
        order: -1;
        height: 60vh;
        width: 100%;
    }

    .video-middle h3 {
        font-size: 24px;
    }

    .video-right {
        width: 48%;
        text-align: start;
        display: inline-block;
    }

    .video-left {
        width: 47%;
        display: inline-block;
    }

    .video-right p {
        font-size: 14px;
        margin-bottom: 0.5rem;
    }

    .video-left img {
        object-fit: cover;
    }
}

/* Large devices (lg) */
@media (min-width: 1024px) {
    .video-middle video {
        object-fit: cover;
    }

    .video-container {
        flex-direction: row;
        flex-wrap: nowrap;
        height: 100vh;
    }

    .video-middle {
        order: 0;
        height: 100%;
        width: 50%;
    }

    .video-right {
        width: 26%
    }

    .video-left {
        width: 30%;
        height: 431px;
    }
}

/* Extra large devices (xl) */
@media (min-width: 1280px) {
    .video-right h3 {
        font-size: 28px;
    }

    .video-right p {
        margin-bottom: 0.5rem;
    }
}

/* 2XL devices (2xl) */
@media (min-width: 1536px) {
}
