.error-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #cccccc;
}

.error-body {
    width: 50%;
    text-align: center;
}

.error-body > * {
    margin-bottom: 20px;
}

.error-body h3 {
    font-size: 30px;
}

.error-body a {
    background-color: #f6a92c;
    color: white;
    border-radius: 6px;
    padding: 10px 20px;
}