.blog-container {
    max-width: 1300px;
    margin: 0 auto;
}

/* .blog_details_container {
  max-width: 1900px;
  margin: 0 auto;
} */

.blog_banner_title {
    color: #000 !important;
    font-family: Montserrat !important;
    font-size: 40px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 48px !important; /* 120% */
}

.banner_title_content {
    color: #888 !important;
    font-family: Montserrat !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 21px !important; /* 150% */

    margin: 30px 0;
}

.banner_title_content p,
.banner_title_content span,
.banner_title_content small,
.banner_title_content ul,
.banner_title_content ol,
.banner_title_content li {
    color: #888 !important;
    font-family: Montserrat !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 21px !important; /* 150% */
}

.blog_banner-content {
    font-family: Montserrat !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 34.2px !important;

    margin: 30px 0;
}

.blog_banner-content li{
    list-style-type: unset !important;
}

.blog_banner-content h1,
.blog_banner-content h2 {
    color: #000 !important;
    font-family: Montserrat !important;
    font-size: 40px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 48px !important; /* 120% */
}

.blog_banner-content p,
.blog_banner-content span,
.blog_banner-content small,
.blog_banner-content ul,
.blog_banner-content ol,
.blog_banner-content li {
    font-family: Montserrat !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 34.2px !important;
}

.blog_banner-content a {
    font-family: Montserrat !important;
    font-size: 18px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 34.2px !important;
}

.blog_banner-content img,
.blog_banner-content video {
    margin-top: 15px !important;
}

.blog_author_text {
    color: rgba(0, 0, 0, 0.38);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 26.6px; /* 190% */
}

.blog_cate_btn {
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    text-transform: capitalize;
    min-width: 80.5px;
    height: 22px;
    padding: 0 10px;
    border-radius: 4px;
    background: #f6a92c;
    border: none;
    outline: none;
}

.blog_card_title {
    color: rgba(0, 0, 0, 0.87);
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.blog_card_content {
    color: rgba(0, 0, 0, 0.57) !important;
    font-family: Montserrat !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 24px !important;
}

.blog_card_content span,
blog_card_content p,
blog_card_content small,
blog_card_content ul,
blog_card_content ol,
blog_card_content li {
    color: rgba(0, 0, 0, 0.57) !important;
    font-family: Montserrat !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 24px !important;
}

.blog_card_image_container {
    overflow: hidden;
}

.blog_card:hover .blog_card_image_container img {
    scale: 1.15;
    transition-duration: 2.5s;
}

.blog_image_overlay_container {
    width: fit-content;
    height: 30px;
    background-color: white;
    padding: 0 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 20px;
    left: 20px;
}

.blog_image_overlay_container:hover {
    background-color: #f6a92c;
}

.blog_image_overlay_container:hover .blog_image_overlay_text {
    color: white;
}

.blog_image_overlay_text {
    color: rgba(0, 0, 0, 0.87);
    font-family: Montserrat !important;
    /* font-family: Futura Bk BT !important; */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.blog-according-container {
    width: 100%;
    min-height: fit-content;
    background-color: #f8f8f8;
    margin: 0 auto;
}

.blog-according-container .title {
    color: #252b42;
    text-align: center;
    font-family: Montserrat;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px; /* 123.81% */
    letter-spacing: 0.2px;
    margin-bottom: 30px;
}

/* blog content section */
.blog-table-content-container {
    width: 100%;
    height: fit-content;
}

.blog-table-content-container .content {
    max-width: 100%;
    margin: 0 auto;
}

.blog-table-content-container h1 {
    color: #252b42;
    text-align: left;
    font-family: Montserrat;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 80% */
    letter-spacing: 0.1px;
}

.blog-table-content-container .content h6 {
    color: #1d1d1d;
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px; /* 200% */
}

.blog-table-content-container .content ul {
    overflow: hidden;
    color: #369;
    text-align: justify;
    text-overflow: ellipsis;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 400;
    line-height: 30.2px; /* 167.778% */
}

.blog-table-content-container .content ul li::before {
    color: #369;
    margin-right: 5px;
    text-decoration-line: none !important;
}

.blog-table-content-container a {
    color: #369;
    display: inline;
    font-size: 18px;
    font-weight: 600;
    padding: 0.5rem 0.1rem;
    text-decoration: none;
}

/* blog products styles */
.blog-products-container {
    width: 100%;
    height: fit-content;
    background: #f8f8f8;
}

.blog-details-paragraph {
    margin-top: 20px;
    color: #6c757d;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 34.2px; /* 190% */
}

.related-products-p {
    color: var(--second-text-color, #737373);
    text-align: center;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.2px;
    margin-bottom: 20px;
}

/* blog about section */
.blog-about-section {
    margin-top: 20px;
    background-image: url("../../assets/images/blog-page/bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    min-height: 556px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.blog-about-section .blog-about-content {
    max-width: 550px;
    height: fit-content;
    border-radius: 20px;
    background: #1c91b3;
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 52px; /* 260% */
    letter-spacing: 0.2px;
    padding: 20px;
}

.blog-about-section .blog-about-content h1 {
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px; /* 260% */
    letter-spacing: 0.2px;
}

.blog-details-right-sidebar {
    max-width: 300px;
    width: 100%;
}

.blog-category-container {
    background-color: white;
    width: 100%;
    border-top: 4px solid #0059a8;
}

.blog-category-container h1 {
    font-size: 22px;
    position: relative;
    margin: 0;
    font-weight: 700;
    display: inline-block;
    padding-bottom: 10px;
}

.category-button {
    height: 40px;
    width: 100%;
    border-bottom: 1px solid;
    color: #000 !important;
    font-family: Montserrat !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: 21px !important;
}

.category-button:hover {
    background-color: #0059a8;
    height: 40px;
    width: 100%;
    border-bottom: 1px solid;
    color: white !important;
    transition: ease-in 0.1s;
}

.category-button svg {
    fill: #000;
    color: #000;
}

.category-button:hover svg {
    fill: white;
    color: white;
}

.related-blog-card img {
    max-width: 100px;
    min-width: 100px;
    width: 100%;
    height: 70px;
    object-fit: cover;
    border: 1px solid rgb(189, 183, 183);
    border-radius: 5px;
    padding: 5px;
}

.related-blog-card a {
    font-size: 14px;
    color: #0e0d0d;
    font-weight: 500;
}

.related-blog-card p {
    font-size: 12px;
    color: #312f2f;
    margin-top: 2px;
}

.treeLine {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.twoLine {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

/* Media query for medium-sized devices */
@media screen and (min-width: 508px) {
    .blog-products-container .grid-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* Media query for medium-sized devices */
@media screen and (min-width: 768px) {
    .blog-products-container .grid-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

/* Media query for large-sized devices */
@media screen and (min-width: 992px) {
    .blog-products-container .grid-container {
        grid-template-columns: repeat(4, 1fr);
    }
}

/* Media query for extra-large devices */
@media screen and (min-width: 1200px) {
    .blog-products-container .grid-container {
        grid-template-columns: repeat(4, 1fr);
    }
}

/* blog according section */
.blog-according-container .accordion {
    --bs-accordion-bg: transparent;
}

.according-item .accordion-button:not(.collapsed) {
    color: #6c757d !important;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 34.2px; /* 190% */
    background: transparent !important;
}

.according-item .accordion-button:not(.collapsed)::after {
    flex-shrink: 0;
    width: 23px;
    height: 23px;
    margin-left: auto;
    content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M4.5 15.75l7.5-7.5 7.5 7.5" /></svg>');
    background-repeat: no-repeat;
    transition: var(--bs-accordion-btn-icon-transition);
}

.according-item {
    border: none !important;
}

.according-item .accordion-button {
    align-items: center;
    color: #6c757d !important;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 34.2px; /* 190% */
    background: transparent !important;
    padding: 0;
}

.according-item .accordion-body {
    padding: 0;
    color: #6c757d;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 34.2px; /* 190% */
}

.according-item span {
    color: #121212;
    text-align: center;
    font-family: Montserrat;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px; /* 260% */
    letter-spacing: 0.2px;
    margin-right: 10px;
}

@media screen and (max-width: 768px) {
    .blog_banner_title {
        font-size: 24px !important;
        font-style: normal;
        font-weight: 500;
        line-height: 28px; /* 120% */
    }

    .blog_banner-content h1,
    .blog_banner-content h2 {
        font-size: 24px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 28px !important; /* 120% */
    }

    .blog-banner {
        width: 100% !important;
        margin: 0 auto;
    }

    .blog-table-content-container .content h1 {
        font-size: 20px;
    }

    .blog-table-content-container .content h6 {
        font-size: 16px;
    }

    .blog-according-container .title {
        font-size: 25px;
    }

    .blog-table-content-container .content ul {
        font-size: 14px;
    }
}
